import { styled } from '../../settings/theme';
import React from 'react';
import classnames from 'classnames';

export interface CardProps {
  children: React.ReactNode;
  className?: string;
}

const Container = styled.div`
  border: 0.12rem solid ${({ theme }) => theme.color.lightGrey2};
  background-color: ${({ theme }) => theme.color.white};
  background-image: url('/images/clouds.svg');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: ${({ theme }) => theme.borderRadius.primary};
  box-shadow: ${({ theme }) => theme.elevation.primary};
  padding: 0.5rem;
  & > .inner {
    border-radius: ${({ theme }) => theme.borderRadius.primary};
    background: rgba(255, 255, 255, 0.7);
    padding: 1rem;
  }
`;

export const Card = ({ children, className }: CardProps) => {
  return (
    <Container className={classnames(className)}>
      <div className="inner">{children}</div>
    </Container>
  );
};
