import React from 'react';
import { styled, theme } from '../../settings/theme';
import { Container } from 'react-bootstrap';

export interface SectionProps {
  fluid?: boolean;
  color?: string;
  spacing?: string;
  children: React.ReactNode;
  className?: string;
  fullscreen?: boolean;
  backgroundSize?: string;
  backgroundImage?: string;
  backgroundColor?: string;
  backgroundPosition?: string;
}

const SectionContainer = styled.section<SectionProps>`
  color: ${(props) => props.color};
  min-height: ${({ fullscreen }) =>
    fullscreen ? 'calc(100vh - 66px)' : undefined};
  padding: ${(props) => props.spacing} 0rem;
  background-image: url('${(props) => props.backgroundImage}');
  background-position: ${(props) => props.backgroundPosition};
  background-repeat: no-repeat;
  background-size: ${(props) => props.backgroundSize};
  background-color: ${(props) => props.backgroundColor};
`;

export const Section = ({
  fluid = false,
  color = theme.color.primary,
  spacing = '1rem',
  children,
  className,
  fullscreen = false,
  backgroundSize,
  backgroundImage,
  backgroundColor,
  backgroundPosition,
}: SectionProps) => {
  return (
    <SectionContainer
      color={color}
      spacing={spacing}
      className={`row align-items-center ${className}`}
      fullscreen={fullscreen}
      backgroundSize={backgroundSize}
      backgroundImage={backgroundImage}
      backgroundColor={backgroundColor}
      backgroundPosition={backgroundPosition}
    >
      <Container fluid={fluid}>{children}</Container>
    </SectionContainer>
  );
};
