export const routeTypes = {
  HOME: {
    key: 'HOME',
    name: 'Home',
    route: '/',
  },
  DO_NOT_SELL: {
    key: 'DO_NOT_SELL',
    name: 'Do Not Sell',
    route: '/do-not-sell',
  },
  QUOTE: {
    MOVE_SIZE: {
      key: 'MOVE_SIZE',
      name: 'Move Size',
      route: '/quote/move-size',
      isStep: true,
    },
    DATE: {
      key: 'DATE',
      name: 'Date',
      route: '/quote/date',
      isStep: true,
    },
    MOVING_TO: {
      key: 'MOVING_TO',
      name: 'Moving To',
      route: '/quote/moving-to',
      isStep: true,
    },
    CALCULATOR: {
      key: 'CALCULATOR',
      name: 'Calculator',
      route: '/quote/calculator',
      isStep: false,
    },
  },
  THANK_YOU: {
    key: 'THANK_YOU',
    name: 'Thank You',
    route: '/thank-you',
  },
} as const;
