import React, { useState } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { styled, theme } from '../../settings/theme';
import { Section } from './Section';
import { StepNavigator } from '../shared/StepNavigator';
import { useRouter } from 'next/router';
import { routeTypes } from '../../core/types/route.type';
import classnames from 'classnames';
import { PrivacyModal } from '../modals/PrivacyModal';
import { TermsModal } from '../modals/TermsModal';
import { NextSeo } from 'next-seo';
import { NextSeoProps } from 'next-seo/lib/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface PageLayoutProps {
  children: React.ReactNode;
  seo?: NextSeoProps;
  onButtonClick?: () => void;
}

const PhoneLink = styled.a`
  color: ${({ theme }) => theme.color.primary};
  font-size: ${({ theme }) => theme.fontSize.primary};
  font-weight: 700;
  text-decoration: none;
`;

const LogoText = styled.strong`
  line-height: 40px;
  margin-left: 10px;
`;

const Children = styled.section`
  margin-top: 66px;
`;

const MoverBirdNavbar = styled(Navbar)`
  background-color: ${({ theme }) => theme.color.overlay.whiter};
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
`;

const Footer = styled.div`
  color: ${({ theme }) => theme.color.white};
  & a {
    color: ${({ theme }) => theme.color.white};
  }
`;

export const PageLayout = ({ seo, children }: PageLayoutProps) => {
  const router = useRouter();
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const quoteSteps = Object.values(routeTypes.QUOTE).reduce((acc, route) => {
    if (route.isStep) {
      acc.push(route.route);
    }
    return acc;
  }, [] as string[]);

  const getCurrentStep = () => {
    const currentStepName = router.pathname;
    return String(
      quoteSteps.indexOf(
        currentStepName as unknown as (typeof quoteSteps)[number],
      ),
    );
  };
  const currentStep = getCurrentStep();
  const quoteMode = currentStep !== '-1';
  return (
    <>
      {seo && <NextSeo {...seo} />}
      <MoverBirdNavbar fixed="top" expand="lg">
        <div className="container">
          <Navbar.Brand href="/">
            <img
              alt="MoverBird"
              src="/images/logo.svg"
              width="40"
              height="40"
              className="d-inline-block align-top"
            />
            <LogoText
              className={classnames({
                'd-none d-lg-inline-block d-md-inline-block': quoteMode,
              })}
            >
              MoverBird.
            </LogoText>
          </Navbar.Brand>

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto" />
          </Navbar.Collapse>
          {quoteMode ? (
            <StepNavigator
              steps={quoteSteps}
              currentStep={Number(currentStep)}
            />
          ) : (
            <PhoneLink
              href="tel:888-555-5555"
              className="d-flex flex-row justify-content-center align-items-center"
            >
              <FontAwesomeIcon icon={faPhone as IconProp} className="mr-2" />
              <div>(888) 555-5555</div>
            </PhoneLink>
          )}
        </div>
      </MoverBirdNavbar>
      <Children>{children}</Children>
      <div className="container-fluid">
        <Section
          spacing="6rem"
          className="text-center"
          backgroundSize="cover"
          backgroundColor={theme.color.primary}
          backgroundImage="/images/night-movers.svg"
          backgroundPosition="bottom center"
        >
          <Footer className="col">
            <img
              alt="MoverBird"
              src="/images/logo-white.svg"
              width="40"
              height="40"
              className="d-inline-block align-top"
            />
            <h3>MoverBird.</h3>
            <small>
              © Copyright {new Date().getFullYear()} All rights reserved.
            </small>
            <p>
              <small>
                <a onClick={() => setShowPrivacy(true)}>Privacy Policy</a>
                {' | '}
                <a onClick={() => setShowTerms(true)}>Terms Of Use</a>
                {' | '}
                <a
                  onClick={async () =>
                    await router.push(routeTypes.DO_NOT_SELL.route)
                  }
                >
                  Do Not Sell My Info
                </a>
              </small>
            </p>
          </Footer>
        </Section>
      </div>
      <PrivacyModal onHide={() => setShowPrivacy(false)} show={showPrivacy} />
      <TermsModal onHide={() => setShowTerms(false)} show={showTerms} />
    </>
  );
};
