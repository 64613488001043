import React from 'react';
import { Modal } from 'react-bootstrap';

export interface PrivacyModalProps {
  show?: boolean;
  onHide: () => void;
}

export const PrivacyModal: React.FC<PrivacyModalProps> = ({ show, onHide }) => {
  return (
    <Modal show={show} size="lg" centered onHide={onHide}>
      <Modal.Header className="text-center" closeButton>
        <Modal.Title>Privacy Policy</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-5">
        <h3>Privacy Policy</h3>

        <p className="mb-5">
          Protecting your private information is our priority. This Statement of
          Privacy applies to www.moverbird.com and Alined Inc. and governs data
          collection and usage. For the purposes of this Privacy Policy, unless
          otherwise noted, all references to Alined Inc., include the website
          www.moverbird.com and are herein collectively referred to and
          referenced in general terms as "MoverBird", "Moverbird Platform",
          "we", "our" or "us". We call a user of the Moverbird Platform "User",
          "Users", "you", "your" or "Client", as appropriate.
        </p>

        <h3>Collection of your Personal Information</h3>

        <p>
          MoverBird may collect personally identifiable information, such as
          your:
        </p>

        <p>
          - Name
          <br />
          - E-mail Address
          <br />- Phone Number
          <br />- Address
        </p>

        <p className="mb-5">
          MoverBird encourages you to review the privacy statements of websites
          you choose to link to from MoverBird so that you can understand how
          those websites collect, use and share your information. MoverBird is
          not responsible for the privacy statements or other content on outside
          websites.
        </p>

        <h3>Use of your Personal Information</h3>

        <p className="mb-5">
          MoverBird collects and uses your personal information to operate its
          website(s) and deliver the services you have requested.
        </p>

        <p className="mb-5">
          MoverBird may also use your personally identifiable information to
          inform you of other products or services available from MoverBird and
          its affiliates. MoverBird may also contact you via surveys to conduct
          research about your opinion of current services or of potential new
          services that may be offered.
        </p>

        <p className="mb-5">
          MoverBird may sell, rent, or lease customer information to third
          parties for the following reasons:
        </p>

        <h3>Provide Related Home Services Quotes </h3>
        <p className="mb-5">
          MoverBird may, from time to time, contact you on behalf of external
          business partners about a particular offering that may be of interest
          to you. In those cases, your unique personally identifiable
          information (e-mail, name, address, telephone number) is transferred
          to the third party. MoverBird may share data with trusted partners to
          help perform statistical analysis, send you email or postal mail,
          provide customer support, or arrange for deliveries. All such third
          parties are prohibited from using your personal information except to
          provide these services to MoverBird, and they are required to maintain
          the confidentiality of your information.
        </p>

        <p className="mb-5">
          MoverBird may keep track of the websites and pages our users visit
          within MoverBird, in order to determine what MoverBird services are
          the most popular. This data is used to deliver customized content and
          advertising within MoverBird to customers whose behavior indicates
          that they are interested in a particular subject area.
        </p>

        <p className="mb-5">
          MoverBird will disclose your personal information, without notice,
          only if required to do so by law or in the good faith belief that such
          action is necessary to: (a) conform to the edicts of the law or comply
          with legal process served on MoverBird or the site; (b) protect and
          defend the rights or property of MoverBird; and, (c) act under exigent
          circumstances to protect the personal safety of users of MoverBird, or
          the public.
        </p>

        <h3>Automatically Collected Information</h3>
        <p className="mb-5">
          Information about your computer hardware and software may be
          automatically collected by MoverBird. This information can include:
          your IP address, browser type, domain names, access times and
          referring website addresses. This information is used for the
          operation of the service, to maintain quality of the service, and to
          provide general statistics regarding use of the MoverBird website.
        </p>

        <h3>Use of Cookies </h3>
        <p className="mb-5">
          MoverBird may use "cookies" to help you personalize your online
          experience. A cookie is a text file that is placed on your hard disk
          by a web page server. Cookies cannot be used to run programs or
          deliver viruses to your computer. Cookies are uniquely assigned to
          you, and can only be read by a web server in the domain that issued
          the cookie to you.
        </p>

        <p className="mb-5">
          One of the primary purposes of cookies is to provide a convenience
          feature to save you time. The purpose of a cookie is to tell the Web
          server that you have returned to a specific page. For example, if you
          personalize MoverBird pages, or register with MoverBird site or
          services, a cookie helps MoverBird to recall your specific information
          on subsequent visits. This simplifies the process of recording your
          personal information, such as billing addresses, shipping addresses,
          and so on. When you return to the same MoverBird website, the
          information you previously provided can be retrieved, so you can
          easily use the MoverBird features that you customized.
        </p>

        <p className="mb-5">
          You have the ability to accept or decline cookies. Most Web browsers
          automatically accept cookies, but you can usually modify your browser
          setting to decline cookies if you prefer. If you choose to decline
          cookies, you may not be able to fully experience the interactive
          features of the MoverBird services or websites you visit.{' '}
        </p>

        <h3>Security of your Personal Information</h3>
        <p className="mb-5">
          MoverBird secures your personal information from unauthorized access,
          use, or disclosure. MoverBird uses the following methods for this
          purpose:{' '}
        </p>
        <p className="mb-5">
          - Google Cloud Encryption At Rest
          <br />- SSL Protocol
        </p>
        <p className="mb-5">
          For more information about Google Cloud Encryption At Rest,
          <br />
          see:{' '}
          <a
            href="https://cloud.google.com/security/encryption-at-rest/default-encryption"
            target="_blank"
          >
            Google Cloud Encryption At Rest
          </a>
        </p>
        <p className="mb-5">
          When personal information (such as a credit card number) is
          transmitted to other websites, it is protected through the use of
          encryption, such as the Secure Sockets Layer (SSL) protocol.{' '}
        </p>

        <h3>Children Under Thirteen</h3>
        <p className="mb-5">
          MoverBird does not knowingly collect personally identifiable
          information from children under the age of thirteen. If you are under
          the age of thirteen, you must ask your parent or guardian for
          permission to use this website.{' '}
        </p>

        <h3>Opt-Out &amp; Unsubscribe</h3>
        <p className="mb-5">
          We respect your privacy and give you an opportunity to opt-out of
          receiving announcements of certain information. Users may opt-out of
          receiving any or all communications from MoverBird by sending a
          request via email to:{' '}
          <a href="mailto:unsubscribe@moverbird.com">
            unsubscribe@moverbird.com
          </a>
        </p>

        <h3>Changes to this Statement </h3>
        <p className="mb-5">
          MoverBird will occasionally update this Statement of Privacy to
          reflect company and customer feedback. MoverBird encourages you to
          periodically review this Statement to be informed of how MoverBird, is
          protecting your information.{' '}
        </p>

        <h3>Contact Information </h3>
        <p className="mb-5">
          MoverBird welcomes your questions or comments regarding this Statement
          of Privacy. If you believe that MoverBird has not adhered to this
          Statement, please contact MoverBird at:{' '}
          <a href="mailto:info@moverbird.com">info@moverbird.com</a>
        </p>
        <p className="mb-5">
          MoverBird welcomes your questions or comments regarding the Terms:
          <address className="mt-3">
            <strong>Alined Inc.</strong>
            <br />
            Los Angeles, CA
            <br />
            Email Address:{' '}
            <a href="mailto:info@moverbird.com">info@moverbird.com</a>
            <br />
            Effective as of March 01, 2021
          </address>
        </p>

        <h3>For California Consumers The Following Applies</h3>

        <h3>Your Rights and Choices</h3>
        <p className="mb-5">
          The CCPA provides consumers (California residents) with specific
          rights regarding their personal information. This section describes
          your CCPA rights and explains how to exercise those rights.
        </p>

        <h3>Access to Specific Information and Data Portability Rights</h3>

        <p className="mb-5">
          You have the right to request that we disclose certain information to
          you about our collection and use of your personal information over the
          past 12 months. Once we receive and confirm your verifiable consumer
          request, we will disclose to you:
          <ul className="mt-3">
            <li>
              The categories of personal information we collected about you.
            </li>
            <li>
              The categories of sources for the personal information we
              collected about you.
            </li>
            <li>
              Our business or commercial purpose for collecting or selling that
              personal information.
            </li>
            <li>
              The categories of third parties with whom we share that personal
              information.
            </li>
            <li>
              The specific pieces of personal information we collected about you
              (also called a data portability request)
            </li>
            <li>
              If we sold or disclosed your personal information for a business
              purpose, two separate lists disclosing:
              <ul>
                <li>
                  sales, identifying the personal information categories that
                  each category of recipient purchased; and
                </li>
                <li>
                  disclosures for a business purpose, identifying the personal
                  information categories that each category of recipient
                  obtained.
                </li>
              </ul>
            </li>
          </ul>
        </p>

        <h3>Deletion Request Rights</h3>
        <p>
          You have the right to request that we delete any of your personal
          information that we collect from you and retained, subject to certain
          exceptions. Once we receive and confirm your verifiable consumer
          request, we will delete (and direct our service providers to delete)
          your personal information from our records, unless an exception
          applies.
        </p>
        <p className="mb-5">
          We may deny your deletion request if retaining the information is
          necessary for us or our service providers to:
          <ol className="mt-3">
            <li>
              Complete the transaction for which we collected the personal
              information, provide a good or service that you requested, take
              actions reasonably anticipated within the context of our ongoing
              business relationship with you, or otherwise perform our contract
              with you.
            </li>
            <li>
              Detect security incidents, protect against malicious, deceptive,
              fraudulent, or illegal activity, or prosecute those responsible
              for such activities.
            </li>
            <li>
              Debug products to identify and repair errors that impair existing
              intended functionality.
            </li>
            <li>Comply with a legal obligation.</li>
          </ol>
        </p>

        <h3>Exercising Access, Data Portability, and Deletion Rights</h3>
        <p>
          To exercise the access, data portability, and deletion rights
          described above, please submit a verifiable consumer request to us by
          email: <a href="mailto:CCPA@moverbird.com">CCPA@moverbird.com</a>
        </p>

        <p>
          Only you or a person registered with the California Secretary of State
          that you authorize to act on your behalf, may make a verifiable
          consumer request related to your personal information. You may also
          make a verifiable consumer request on behalf of your minor child.
        </p>
        <p>
          You may only make a verifiable consumer request for access or data
          portability twice within a 12-month period. The verifiable consumer
          request must:
          <ul className="mt-3">
            <li>
              Provide sufficient information that allows us to reasonably verify
              you are the person about whom we collected personal information or
              an authorized representative. (At times we may require you to
              confirm a link sent to your phone via SMS or email)
            </li>
            <li>
              Describe your request with sufficient detail that allows us to
              properly understand, evaluate, and respond to it.
            </li>
          </ul>
        </p>

        <p className="mb-5">
          We cannot respond to your request or provide you with personal
          information if we cannot verify your identity or authority to make the
          request and confirm the personal information relates to you. Making a
          verifiable consumer request does not require you to create an account
          with us. We will only use personal information provided in a
          verifiable consumer request to verify the requestor's identity or
          authority to make the request.
        </p>

        <h3>Response Timing and Format</h3>
        <p className="mb-5">
          We endeavor to respond to a verifiable consumer request within 45 days
          of its receipt. If we require more time (up to 90 days), we will
          inform you of the reason and extension period in writing. We will
          deliver our written response by email to the email that was verified.
          Any disclosures we provide will only cover the 12-month period
          preceding the verifiable consumer request's receipt. The response we
          provide will also explain the reasons we cannot comply with a request,
          if applicable. We do not charge a fee to process or respond to your
          verifiable consumer request unless it is excessive, repetitive, or
          manifestly unfounded. If we determine that the request warrants a fee,
          we will tell you why we made that decision and provide you with a cost
          estimate before completing your request.
        </p>

        <h3>Non-Discrimination</h3>
        <p className="mb-5">
          We will not discriminate against you for exercising any of your CCPA
          rights. Unless permitted by the CCPA, we will not:
          <ul className="mt-3">
            <li>Deny you goods or services.</li>
            <li>
              Charge you different prices or rates for goods or services,
              including through granting discounts or other benefits, or
              imposing penalties.
            </li>
            <li>
              Provide you a different level or quality of goods or services.
            </li>
            <li>
              Suggest that you may receive a different price or rate for goods
              or services or a different level or quality of goods or services.
            </li>
          </ul>
        </p>
      </Modal.Body>
    </Modal>
  );
};
