import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames';
import { styled } from '../../settings/theme';
import { useRouter } from 'next/router';

interface StepNavigatorProps {
  steps: string[];
  currentStep: number;
}

const Container = styled.div`
  text-align: center;
  .step-wrapper {
    color: ${({ theme }) => theme.color.lightGrey};
    background: ${({ theme }) => theme.color.white};
    border: 0.2rem solid ${({ theme }) => theme.color.lightGrey};
    font-size: ${({ theme }) => theme.fontSize.primary};
    line-height: 2.3rem;
    width: 2.5rem;
    height: 2.5rem;
    display: inline-block;
    margin: 0 0.6rem;
    cursor: not-allowed;
    border-radius: ${({ theme }) => theme.borderRadius.rounded};

    &.active {
      color: ${({ theme }) => theme.color.primary};
      border: 0.2rem solid ${({ theme }) => theme.color.primary};
      cursor: default;
    }
    &.done {
      color: ${({ theme }) => theme.color.white};
      background: ${({ theme }) => theme.color.primary};
      border: 0.2rem solid ${({ theme }) => theme.color.primary};
      cursor: pointer;
      & > a {
        color: ${({ theme }) => theme.color.white};
        display: inline-block;
        border-radius: ${({ theme }) => theme.borderRadius.rounded};
        width: 100%;
        height: 100%;
      }
      &:after {
        background: ${({ theme }) => theme.color.lightBlue};
      }
    }
    &:after {
      content: ' ';
      position: absolute;
      width: 2.2rem;
      margin-top: 0.8rem;
      height: 0.45rem;
      background: ${({ theme }) => theme.color.lightGrey};
      z-index: -1;
    }
    &:last-child:after {
      display: none;
    }
  }
`;

export function StepNavigator({ steps, currentStep }: StepNavigatorProps) {
  const router = useRouter();
  const buildStep = (step: string, index: number): React.ReactNode => {
    const active = currentStep === index;
    const done = currentStep > index;

    return (
      <span
        key={index}
        role="button"
        className={classnames('step-wrapper', { active, done })}
      >
        {done ? (
          <span onClick={() => router.push(step)}>
            <FontAwesomeIcon icon={faCheck as any} />
          </span>
        ) : (
          index + 1
        )}
      </span>
    );
  };

  return (
    <Container className="step-navigator" role="navigation">
      <strong>{steps.map((step, index) => buildStep(step, index))}</strong>
    </Container>
  );
}
